<template>
    <div class="about-main">
        <top-nav></top-nav>
        <header-guide></header-guide>
        <bread-crumb></bread-crumb>
        <div
            class="sp-banner"
            :style="{backgroundImage:'url('+$img.about_banner+')'}"
        >
            <div class="container">
                <div class="sp-banner-txt">关于我们</div>
            </div>
        </div>
        <div class="main">
            <div class="container">
                <aside class="aside-panel">
                    <ul class="col-items">
                        <li
                            :class="{'active':item.url===web_url}"
                            class="item"
                            v-for="(item,index) in cloumn"
                            :key="index"
                            @click="toggle(index)"
                        >
                            <a :href="item.url">{{item.type}}</a>
                        </li>
                    </ul>
                </aside>
                <div class="contarea-panel">
                    <div class="contarea">
                        <div
                            class="about-txt-cont"
                            id="thiscontent"
                        >
                            <div
                                class="desc"
                                v-html="util.reHtml(article.content)"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-panel">
                    <sidebar-panel></sidebar-panel>
                </div>
            </div>
        </div>
        <global-footer></global-footer>
    </div>
</template>

<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import SidebarPanel from "@/components/common/SidebarPanel.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
export default {
    name: "about",
    metaInfo() {
        return {
            title: "询龙网 - 工程领域专业服务众包平台",
            meta: [
                {
                    name: "keywords",
                    content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
                }, {
                    name: "description",
                    content: "询龙网是一家互联网工程领域咨询服务众包平台，该平台为工程领域技术项目招投标、短期日薪工作招投标、执从业人员资源分享、酬金托管提供一站式服务。在此平台上，雇主可以免费发布项目，寻找合适的工程极客，可以选择在平台托管酬金，方便快捷地完成自己的任务。找技术项目的企业、工作室及兼职工程技术人员可以注册成为工程极客，参与投标，完成项目并获取酬金。询龙网由北京询龙云和科技有限公司自主研发并拥有完整知识产权。"
                }
            ]
        }
    },
    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        SidebarPanel, //右边公共模块
        GlobalFooter //公共底部
    },
    data() {
        return {
            currentIndex: 0,
            web_url: this.$route.path,
            cloumn: [
                {
                    type: "网站介绍",
                    url: "/about/index"
                },
                {
                    type: "联系我们",
                    url: "/contact/index"
                }
            ],
            article: [],
        };
    },
    methods: {
        toggle(index) {
            this.currentIndex = index;
        },
        getAbout() {
            let _this = this;
            this.post(
                "/article/index/detail",
                {
                    id: 1
                },
                function (res) {
                    if (res.code == 200) {
                        _this.article = res.data;
                    }
                }
            );
        },
        thiscontent() {
            document.querySelector("#thiscontent").scrollIntoView(false);
        }
    },
    created() {
        this.getAbout();
    },
    mounted() {
        this.thiscontent();
    }
};
</script>



